<template>
  <EuiDialog @onClose="$emit('closeDialog')" size="large" :open="true">
    <template v-slot:header>
      {{ $t('settings.dialog.addUsers.title') }}
    </template>
    <EuiGrid>
      <EuiGridRow>
        <EuiGridColumn>
          <span v-if="selected.length > 0" class="font-bold">{{ $tc('settings.users.selected', selected.length).toLowerCase() }}</span>
        </EuiGridColumn>
        <EuiGridColumn width="1/3">
          <EuiSearchBar :placeholder="$t('searchBar.placeholder')"
                        v-model.trim="searchInput"
                        @change="searchUser"
                        @search="searchUser"
                        @clear="resetSearchInput"
                        clearable
          />
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
    <EuiAdvancedTable v-model="users" :selectedValue.sync="selected">
      <EuiATableRow selectable="multiple" autoSelect slot="tableRow" slot-scope="{ item }">
        <EuiATableCell :label="$t('settings.dialog.addUsers.table.name')">{{ item.fullname }}</EuiATableCell>
        <EuiATableCell :label="$t('settings.dialog.addUsers.table.email')">{{ item.email }}</EuiATableCell>
        <EuiATableCell :label="$t('settings.dialog.addUsers.table.group')">{{ item.group }}</EuiATableCell>
      </EuiATableRow>
      <template #tableEmptyState>
        <p>{{ $t('settings.dialog.addUsers.noUsers') }}</p>
      </template>
    </EuiAdvancedTable>
    <EuiPagination v-if="users.length"
                   class="mt-4"
                   :value="pagination.current"
                   @change="goToPage"
                   :paginationText="$t('pagination.text')"
                   :totalPages="pagination.total"/>
    <template v-slot:footer>
      <EuiButton variant="raised" color="primary" @click="$emit('updateUsers', selected)">{{ $t('button.add') }}</EuiButton>
    </template>
  </EuiDialog>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  /**
   * The name of the page.
   */
  name: 'addUsersDialog',

  /**
   * The data the page can use.
   *
   * @returns {Object} The data.
   */
  data() {
    return {
      selected: [],
      users: [],
      searchParams: {
        orders: [{ fieldName: 'lastname', orderBy: 'DESC' }],
        params: [],
        offset: 0,
        limit: 10,
      },
      searchInput: '',
    };
  },

  /**
   * The methods the page can use.
   */
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.settingsUsers.pagination,
    }),
  },
  methods: {
    ...mapActions({
      search: 'ModuleEdocSign/settingsUsers/search',
      searchGroup: 'ModuleEdocSign/settingsGroups/search',
      findAll: 'ModuleEdocSign/settingsUsers/findAll',
    }),
    resetSearchInput() {
      this.searchInput = '';
      this.searchParams.params = [];
      this.searchUser();
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.searchUser();
    },
    searchUser() {
      if (this.searchInput) {
        this.searchParams.params.push({
          paramName: 'token', paramValue: this.searchInput,
        });
      }
      const users = this.search(this.searchParams).then((response) => {
        if (response && response.accounts) {
          return response.accounts;
        }
      });
      const groups = this.searchGroup({ orders: [{ fieldName: 'name', orderBy: 'DESC' }], offset: 0, limit: 10 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      Promise.all([users, groups]).then((values) => {
        for (let i = 0; i < values[0].length; i += 1) {
          values[0][i].group = values[1].find((element) => element.id === values[0][i].userGroupId).name;
        }
        this.users = values[0];
      });
    },
  },

  /**
   * This method will be fired once the application has been mounted.
   */
  mounted() {
    this.searchUser();
  },
};
</script>
